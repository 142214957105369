// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-services-car-charging-station-installation-js": () => import("./../../../src/pages/services/car-charging-station-installation.js" /* webpackChunkName: "component---src-pages-services-car-charging-station-installation-js" */),
  "component---src-pages-services-commercial-electrician-js": () => import("./../../../src/pages/services/commercial-electrician.js" /* webpackChunkName: "component---src-pages-services-commercial-electrician-js" */),
  "component---src-pages-services-energy-efficiency-installation-js": () => import("./../../../src/pages/services/energy-efficiency-installation.js" /* webpackChunkName: "component---src-pages-services-energy-efficiency-installation-js" */),
  "component---src-pages-services-home-automation-installation-js": () => import("./../../../src/pages/services/home-automation-installation.js" /* webpackChunkName: "component---src-pages-services-home-automation-installation-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-overview-js": () => import("./../../../src/pages/services/overview.js" /* webpackChunkName: "component---src-pages-services-overview-js" */),
  "component---src-pages-services-residential-electrical-services-js": () => import("./../../../src/pages/services/residential-electrical-services.js" /* webpackChunkName: "component---src-pages-services-residential-electrical-services-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

